import type { AppProps } from 'next/app';
import NextHead from 'next/head';
import Error from 'next/error';
import { NextIntlClientProvider, IntlError, IntlErrorCode } from 'next-intl';
import { Global, ThemeProvider } from '@emotion/react';

import { Content } from '@silvertours/common-landingpages-i18n';
import {
  BilligerMietwagenSiteViewContent,
  HasOptionalError,
  PageContent,
  PageOfSiteProps,
} from '@silvertours/common-landingpages-view';
import {
  DocumentLegacy,
  LoginProvider,
  StructuredDataWidget,
} from '@silvertours/front-features';
import { SiteLegacy } from '@silvertours/front-pages';
import {
  account,
  analytics,
  ContentModuleContext,
  SiteInfoProvider,
} from '@silvertours/front-entities';
import { Styles, Theme } from '@silvertours/front-shared';
import { Context, StyleLegacy } from '@silvertours/front-legacy-shared';

import { makeBmClientContext } from '../app';

type BilligerMietwagenSiteProps = PageOfSiteProps<
  BilligerMietwagenSiteViewContent,
  PageContent,
  Content
> &
  HasOptionalError;

const LandingPageApp = ({ pageProps, Component }: AppProps) => {
  const props = pageProps as BilligerMietwagenSiteProps;

  const { brand, document, i18n, locale, page, products, settings } = props;

  if (props.error) {
    // eslint-disable-next-line no-console
    console.error(
      'Something went wrong while rendering the page:',
      props.error.data.error?.message,
    );
    return (
      <Error
        statusCode={props.error.response?.status ?? 400}
        title={props.error.message}
      />
    );
  }

  const onIntlError = (error: IntlError) => {
    // prevent displaying `MISSING_MESSAGE` errors
    // for more info: https://github.com/amannn/next-intl/issues/32
    if (error.code !== IntlErrorCode.MISSING_MESSAGE) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const context = makeBmClientContext(
    locale,
    document.content,
    settings,
    brand,
  );

  const defaultTimeZone = 'Europe/Paris';

  return (
    <NextIntlClientProvider
      locale={locale}
      timeZone={defaultTimeZone}
      messages={i18n}
      onError={onIntlError}
    >
      <Context.ClientContextProvider value={context}>
        <ContentModuleContext.Provider value={page}>
          <SiteInfoProvider>
            <account.AccountProvider>
              <LoginProvider>
                <DocumentLegacy.Head
                  headComponent={NextHead}
                  document={document.content}
                />
                <analytics.gtm.Script id={settings.analytics.gtmId} />
                <ThemeProvider theme={Theme.defaultTheme}>
                  <Global styles={Styles.Global.fonts} />
                  <StyleLegacy.CSSReset theme={Theme.defaultTheme} />
                  <SiteLegacy.BilligerMietwagenSite {...props}>
                    <Component {...page} />
                  </SiteLegacy.BilligerMietwagenSite>
                </ThemeProvider>
                <StructuredDataWidget products={products} page={page} />
              </LoginProvider>
            </account.AccountProvider>
          </SiteInfoProvider>
        </ContentModuleContext.Provider>
      </Context.ClientContextProvider>
    </NextIntlClientProvider>
  );
};

export default LandingPageApp;
